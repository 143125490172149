.history-tables {
  .convert-coin-form {
    margin-bottom: 30px;
  }

  .card {
    .card-header {
      border-bottom: none !important;
      padding: 30px 30px 0 30px !important;
      margin: 10px 5px 10px 19px;

      h5 {
        font-weight: bold;
        font-size: 21px;
      }
    }
  }
  .scrollable-container{
    overflow-y: auto;
    max-height: 500px;
  }
  .card-body{
    overflow-x: auto;
    max-height: 550px;

  }

  .history-btns {
    display: flex;

    .btn1 {
      background-color: rgba(63, 111, 100, 0.21) !important;
      color: #38675c;
    }

    .btn2 {
      background-color: rgba(180, 139, 100, 0.15) !important;
      margin-left: 10px;
      color: #977452;
    }
  }

  td {
    vertical-align: middle;
  }
  .history-dropdown{
    form{
      display: flex;
      flex-direction: row;
      align-items: center;
      .form-control{
        min-width: 140px;
      }
      .text-dropdown{
        margin-right: 10px;

      }
      .mb-3{
        margin-bottom: 0 !important;
      }
    }
  }
}