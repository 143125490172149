.total-balance-banner {
    margin: 15px;
    border-radius: 18px;
    display: flex;
    flex-flow: row nowrap;
    height: 200px;
    width: 100%;

    .left-image {
        > img {
            height: 200px;
            width: auto;
        }
    }

    .right-content {
        width: 100%;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        height: 100%;
        background-color: white;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        padding: 0 30px;

        > .title {
            font-size: 20px;
            font-weight: bold;
        }

        > .balance {
            font-weight: 700;
            color: rgb(114, 117, 124);
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &.dark {
        .right-content {
            background-color: #111727;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

@media (max-width: 920px) {
    .total-balance-banner {
        .right-content {
            border-radius: 18px;
        }
    }
}
