.editable-image {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    border: 15px solid rgba(36, 105, 92, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    > img {
        border-radius: 200px;
        width: 100px;
        background-size: cover;
        border-radius: 200px;
    }

    > .edit-button:hover {
        cursor: pointer;
    }
    
    > .edit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: white;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: box-shadow ease 200ms;

        
        > svg {
            width: 20px;
            height: 20px;
            color: #3f6f64;
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }

    .wrapper{
        display:inline-block;
        position:relative;
    }

    input[type=file]{
        opacity:0;
        position:absolute;
        cursor: pointer;
    }
}

