.crypto-card {
    border: none !important;
    width: 100%;
    margin-top: 20px;

    > .col {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > .card {
            width: 250px;
            height: 180px;
            background-color: #3f6f64 !important;
            border: none !important;
            margin: 0;
        }
    }

    .btn {
        background-color: #edc39b !important;
    }

    &.loaded {
        .card {
            background-image: url('../../../assets/images/crypto_card_background.png') !important;
            background-size: 100%;
            background-position: center;
        }
    }
}
