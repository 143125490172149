.bisbull-toast-banner {
    width: 100%;
    border-radius: 10px;
    background-color: #ebf0ee;
    padding: 10px 18px;
    display: none;
    color: #3f6f64;

    &.show {
        display: block;
    }
}

body.dark-only {
    .bisbull-toast-banner {
        color: #a2c5b7;
        background-color: rgba(#3f6f64, 0.2);
    }
}