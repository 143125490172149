html, body, #root {
    width: 100%;
    height: 100%;
}

body {
    overflow: hidden;
}

.page-wrapper {
    > .page-body-wrapper > .page-body {
        margin-top: 83px;
        margin-left: 290px;
        padding-bottom: 100px !important;
    }
}

.dark-scroll {
    scrollbar-face-color: #3f6f64;
    scrollbar-base-color: #3f6f64;
    scrollbar-3dlight-color: #3f6f64;
    scrollbar-highlight-color: #3f6f64;
    scrollbar-track-color: #121726;
    scrollbar-arrow-color: #121726;
    scrollbar-shadow-color: #3f6f64;
    scrollbar-dark-shadow-color: #3f6f64;

    ::-webkit-scrollbar {
        width: 15px;
        height: 10px;
    }
    ::-webkit-scrollbar-button {
        background-color: #3f6f64;
    }
    ::-webkit-scrollbar-track {
        background-color: #3f6f64;
    }
    ::-webkit-scrollbar-track-piece {
        background-color: #121726;
    }
    ::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #3f6f64;
        border-radius: 15px;
    }
    ::-webkit-scrollbar-corner {
        background-color: #121726;
    }
    ::-webkit-resizer {
        background-color: #121726;
    }
}
