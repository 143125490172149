.bisbull-landing-middle {
    width: 100%;
    padding-bottom: 200px;
    background-color: white;
    
    > .titles {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 200px 30px 80px;

        > .title {
            font-size: 50px;
            text-transform: uppercase;
            color: #3f6f64;
        }

        > .subtitle {
            font-size: 25px;
            color : #72757c;
        }
    }

    > .cards {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        > .col {
            flex: 0;
            width: fit-content;
            
            > .card {
                height: 420px;
                width: 350px;
                text-align: center;

                .card-body {
                    overflow-x: hidden !important;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 120px;
                        width: 120px;
                        margin-bottom: 10px;
                    }
                    .title {
                        font-size: 28px;
                        color: #2c323f;
                        font-weight: 600;
                        white-space: nowrap;
                    }
    
                    .subtitle {
                        font-size: 18px;
                        color: #72757c;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .bisbull-landing-middle {
        >.cards {
            display: flex;
            flex-flow: column nowrap;
        }
    }
}