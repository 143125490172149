.send-consult {
    .mb-4 {
        margin-bottom: 0 !important;
    }
    h4 {
        font-size: 23px;
        font-weight: bold;
        text-align: left;
    }

    .form-label {
        font-size: 16px;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #72757c;
    }

    .form-input {
        min-width: 200px;
    }

    .form-control {
        margin-bottom: 25px;
    }

    .bigger {
        .form-control {
            resize: none;
            min-height: 100px;
        }
    }
    .btn-right {
        float: right;
        margin-top: 20px;
    }

    .input-file {
        .input {
            display: none;
        }
    }

    #file-upload {
        display: none;
    }

    .file-input {
        font-size: 14px;
        color: #717171;
        display: block;
        width: 100%;
        height: auto;
        min-height: calc(1.5em + 0.75rem + 2px);
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        p{
            margin-top: 0.375rem;
            padding: 0 0.75rem 0 0.75rem;
            height: auto;
        }
    }
    .file-label {
        margin-top: 15px;
        display: flex;
        width: 110px;
        height: 30px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;

        p {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: #3f6f64;
        }
    }
    .country-form {
        .text-dropdown {
            height: 60px;
        }
    }
}
