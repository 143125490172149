@media screen and (max-width: 767px) {
    .terms-and-conditions {
        h4 {
            margin-top: 40px;
        }
        .card {
            height: 100%;
            margin-top: 0;
        }
    }
}
