.bisbull-dashboard-container {
    .dropdown-container {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: left;
        margin-bottom: 20px;
        margin-left: 40px;
    }
    .text-dropdown {
        .options-dropddown-toggle {
            padding-left: 10px !important;
            border: 1px solid #f2f2f2;
            margin-left: 10px;

            > p {
                margin-right: 10px;
            }
        }
    }
}

// responsive for mobile
@media (max-width: 1100px) {
    .buy-btn {
        margin-top: 25px;
    }
}
