.help-center {
    .card {
        height: 610px;
        margin-top: 80px;

        .card-header {
            border-bottom: none !important;
            display: flex;
            justify-content: center;
            margin-top: 30px;

            h5 {
                font-size: 23px;
                font-weight: bold;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
            }
        }
    }

    .accordion {
        hr {
            margin-left: 45px;
            margin-top: 10px;
        }

        .accordion-header {
            margin-left: 40px;

            .row {
                display: flex;
                align-items: center;

                .icon {
                    display: flex;
                    justify-content: flex-end;

                    i {
                        color: #3f6f64;
                        font-size: 25px;
                    }
                }
            }
        }

        .accordion-button {
            border: none !important;
            background-color: inherit;
            width: 100%;

            p {
                flex-grow: 0;
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                color: #72757c;
            }
        }
    }

    .accordion-body {
        margin-left: 45px;
        margin-bottom: 35px;
        flex-grow: 0;
        font-size: 15px;
        letter-spacing: normal;
        color: #72757c;

        p {
            text-align: left;
        }
    }
}

@media screen and (max-width: 767px) {
    .help-center {
        .card {
            height: 100%;
            margin-top: 0;

            .card-header {
                margin-top: 0;
            }
        }

        .accordion {
            .accordion-header {
                margin-left: 0;
            }

            .accordion-body {
                margin-left: 0;
            }
        }
    }
}
