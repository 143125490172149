.input-amount {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    > input {

        border: none;
        border-bottom: 1px solid #2c323f;
        border-radius: 0;
        text-align: center;
        font-size: 35px;
        font-family: Roboto, sans-serif;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
        border-color: none;
        box-shadow: none;
    }

    .suffix-label {
        flex-flow: row nowrap;
        margin: 0;
        position: absolute;

        &.suffix-label {
            right: 30px;
            margin-top: 31px;
        }
    }
}

// HIDE DEFAULT ARROWS

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
