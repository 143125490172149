.slick-carousel {
    margin: 0 45px 0 45px;

    .card {
        min-width: 230px;
        //min-height: 215px;

        .card-body {
            padding: 20px;
        }
    }

    .slick-arrow {
        margin: 0 0 0 -5px;
    }

    .slick-prev:before {
        color: #23695b;
        font-size: 30px;
    }
    .slick-next:before {
        color: #23695b;
        font-size: 30px;
    }

    .slick-list {
        height: 220px !important;
    }

    .slick-dots {
        bottom: -25px !important;
    }

    .slick-dots li button:before {
        color: #23695b;
        font-size: 15px;
    }
}
