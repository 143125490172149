.bisbull-market-container {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    height: calc(100vh - 215px);
    > .main-container {
        display: flex;
        flex: 1;
        flex-flow: row nowrap;
        > .graphic-card-conta iner {
            min-width: 55vw;
        }
        .card {
            margin-bottom: 10px;
        }
    }

    .text-dropdown {
        .options-dropddown-toggle {
            padding-left: 10px !important;
            border: 1px solid #f2f2f2;

            > p {
                margin-right: 10px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bisbull-market-container {
        margin-left: 130px;
    }
    .responsive-sidebar {
        margin-left: 0px;
    }
}
