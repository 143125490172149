.robot-switch {
  .switch {
    width: 100%;

    input {
      display: none;

      &:checked + .switch-state {
        background-color: var(--theme-default);

        &:before {
          transform: translateX(137px);
        }
      }
    }
  }
}