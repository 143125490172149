.input-card-tab-container {
    display: flex;
    flex-flow: row wrap;

    > .col {
        margin-top: 20px;
    }
}
.market-input-card {
    .card {
        margin-bottom: 10px;
    }

    .card-body {
        min-height: 50%;
    }

    .load-wheel-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 210px;
    }
}
