.bisbull-landing {
    height: 100%;
    width: 100%;

    > .header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        width: 100%;
        height: 90px;
        padding: 0 40px;
        
        > .brand-container {
            display: flex;
            flex-flow: row nowrap;
            > img {
                height: 50px; 
                padding-right: 10px;
            }
        }

        .links {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            *:not(:last-child) {
                margin-right: 10px;
            }

        }
    }

    > .content {
        height: calc(100% - 90px);
        width: 100%;
        overflow: auto;
    }
}

@media screen and (max-width: 830px) {
    .bisbull-landing {
        
        >.header {
            padding-top: 20px;
            height: unset;
            flex-flow: column nowrap;
            .links {
                margin: 20px 0;
                flex-flow: column nowrap;
            }
        }
    }
    
    >.content {
        height: calc(100% - 230px);
    }
}