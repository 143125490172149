.bisbull-payment-method-edition {
    .pin-code-input {
        .react-code-input {
            input {
                margin: 0px !important;
                height: 36px !important;
                margin-right: 8px !important;
                background-color: #fff !important;
                border-color: #e6edef !important;
                color: #717171 !important;
            }
        }

        &.dark {
            .react-code-input {
                display: flex;
                input {
                    background-color: transparent !important;
                    background-color: #111727 !important;
                    color: rgba(255, 255, 255, 0.7) !important;
                    border-color: rgba(36, 105, 92, 0.2) !important;
                }

                input:focus {
                    box-shadow: 0 0 0 0.2rem rgb(171 140 228 / 5%) !important;
                }
            }
        }
    }
}
