.bisbull-form-input {
    .password-error-container {
        display: flex;
        flex-flow: row wrap;
    }
    .react-tel-input {
        .form-control {
            width: inherit;
            border: 1px solid #e6edef;
            border-radius: 0.25rem;
        }
        .flag-dropdown {
            background-color: #e7edef;
        }
    }
    .disabled {
        .selected-flag {
            cursor: not-allowed;
        }
        .form-control {
            color: #111727;
            background-color: #e9ecef;
        }
    }
    .phone-input-dark {
        .form-control {
            background-color: #111727;
            border: 1px solid rgba(36, 105, 92, 0.2);
        }
        .flag-dropdown {
            background-color: #111727;
            border: 1px solid rgba(36, 105, 92, 0.2);
        }
        .selected-flag {
            &:not(:hover) {
                background-color: #111727;
            }

            &:hover {
                background-color: #111727;
            }
        }
        .country-list {
            background-color: #111727;
            border: 1px solid rgba(36, 105, 92, 0.2);
            color: #fff;
        }
        .country.highlight {
            background-color: #000;
        }
        .country {
            &:hover {
                background-color: #000;
            }
        }
    }
    .phone-input-dark.disabled {
        .form-control {
            color: white !important;
        }
    }
}
