.bisbull-landing-bottom {
    width: 100%;
    background-image: linear-gradient(to bottom, #3f6f64 0%, #2f564c 100%);
    padding: 120px;
    padding-bottom: 80px;
    position: relative;

    > .content {
        flex-flow: row nowrap;
        padding-bottom: 0;

        > .titles {
            margin-right: 100px;
            margin-bottom: 20px;

            > .title {
                margin-bottom: 20px;
                color: white;
                font-size: 40px;
                font-weight: bold;
                line-height: 40px;
            }

            > .buttons {
                display: flex;
                flex-flow: column nowrap;
                width: 150px;

                .btn.btn-light {
                    color: #3f6f64;
                    margin-bottom: 10px;
                }

                .btn.btn-outline-light {
                    color: white;
                    white-space: nowrap;
                }
            }
        }

        > .links {
            color: white;
            margin-bottom: 20px;

            > .title {
                font-size: 25px;
                font-weight: bold;
            }
            
            > .items {
                a {
                    color: white;
                }
                font-size: 23px;
            }
        }
    }

    > .social {
        display: flex;
        flex-flow: row nowrap;
        color: white;

        > *:not(:last-child) {
            margin-right: 30px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .bisbull-landing-bottom {
        .content {
            align-items: center;
            flex-flow: column nowrap;
        }
    }
}