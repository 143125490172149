.sidebar {
    flex-flow: column wrap;
    
    .sidebar-user {
        align-items: center;
        margin-bottom: 0px !important;

        .col-8 {
            padding-top: 10px;
            padding-left: 15px;

            h5 {
                font-family: Montserrat;
                font-size: 20px;
                font-weight: bold;
                color: #2c323f;
            }
        }

        .col-1 {
            padding-top: 20px;
            max-width: 0;
            position: absolute;
            float: right;
            padding-left: 245px;
        }

        .image-container {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            height: 70px;
            width: 70px;
            border: 10px solid rgba(36, 105, 92, 0.2);
            border-radius: 50px;
            user-select: none;

            img {
                border: none !important;
                width: 50px !important;
            }
        }

    }

    > .sidebar-nav {
        overflow-y: auto;
        height: calc(100% - 450px);
        box-shadow: 0 0 2px -1px #888;
    }

    .switch {
        width: 100%;
        input {
            display: none;

            &:checked + .switch-state {
                background-color: var(--theme-default);

                &:before {
                    transform: translateX(115px);
                }
            }

            &:focus + .switch-state {
                box-shadow: 0 0 1px var(--theme-default);
            }
        }

        .dark-switch {
            background-color: rgb(32, 41, 56);
        }
    }
}
