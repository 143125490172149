.bisbull-login-2fa {
  .form-label{
    font-size: 16px;
    font-weight: 600;
  }

  .react-code-input {
    display: flex !important;
    justify-content: center;
  } 
  
  .buttons-container {
    > * {
      margin-left: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .bisbull-form-input {
    > label {
      margin-bottom: 30px;
    }
  }
}