.user-settings {
    .separator {
        border-right: 1px solid #d3d3d3;
    }
}

@media screen and (max-width: 750px) {
    .user-settings {
        display: flex;
        flex-flow: column nowrap;

        .bisbull-tabs {
            display: block !important;
        }

        .separator {
            margin: 30px;
            border-bottom: 1px solid #d3d3d3;
        }
    }
}
.needs-validation{
    .profile-phone-input {
        &.dark {
            .form-control {
                border: 1px solid rgba(79, 211, 185, 0.2);
            }
        }
    }
}