.wallet-convert-cryptos {
    > .card {
        > .card-body {
            width: 100%;
        }
    }

    .dropdown.text-dropdown {
        height: 45px;

        &:hover {
            cursor: pointer;
        }

        .options-dropddown-toggle {
            p {
                min-width: 60px;
            }
        }

        .svg-inline--fa {
            width: 13px;
            padding: 3px;

            &:hover {
                cursor: pointer;
                background: #eaf0ed;
                padding: 3px;
                border-radius: 10px;
            }
        }
    }

    .switch-coin-button {
        top: 273px;
        background: #ebf0ee;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 2;
        color: #3f6f64;
    }

    .btn-disabled {
        cursor: not-allowed;
        color: #b3b3b3;
    }

    .show {
        vertical-align: center;
    }

    .dropdown-item {
        .row {
            align-items: center;
        }
    }

    @media screen and (max-width: 1435px) {
        .column-reverse-rows {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    @media screen and (max-width: 1563px) {
        .column-reverse-rows {
            display: flex;
            flex-direction: row;
        }
    }

    @media screen and (max-width: 435px) {
        .column-reverse-rows {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
