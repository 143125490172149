.bisbull-operator-users {
    .search-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        width: 400px;

        > p {
            margin-right: 10px;
        }

        // > input {}

        > .loader-box {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;

            &.show {
                opacity: 1;
            }
            > .loader-7 {
                height: 30px !important;
                width: 30px !important;
            }
        }
    }
}