.bisbull-landing-top {
    width: 100%;
    background-color: #f5f7fb;

    .texts-container {
        padding: 140px 0 140px 140px;

        > .brand-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 20px;

            > .icon-box {
                width: 200px;
                height: 200px;

                > .icon {
                    height: 200px;
                    padding-right: 10px;
                }
            }
            > .text-box {
                width: 500px;
                height: 250px;

                > .text {
                    height: 250px;
                    padding-right: 10px;
                }
            }
        }

        .title {
            font-size: 44px;
            line-height: 50px;
            color: #2c323f;
            margin-bottom: 20px;

            strong {
                font-weight: bold;
            }
        }

        a {
            button {
                width: 180px;
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .bisbull-landing-top {
        > .image-container {
            display: none;
        }
    }
}

@media screen and (max-width: 830px) {
    .bisbull-landing-top {
        .texts-container {
            padding: 100px 0 100px 100px;

            > .brand-container {
                > .icon-box {
                    width: 120px;
                    height: 120px;

                    > .icon {
                        height: 120px;
                        padding-right: 5px;
                    }
                }
                > .text-box {
                    width: 340px;
                    height: 170px;

                    > .text {
                        height: 170px;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .bisbull-landing-top {
        .texts-container {
            padding: 60px 0 60px 60px;

            > .brand-container {
                > .icon-box {
                    width: 80px;
                    height: 80px;

                    > .icon {
                        height: 80px;
                        padding-right: 0px;
                    }
                }

                > .text-box {
                    width: 240px;
                    height: 120px;

                    > .text {
                        height: 120px;
                        padding-right: 5px;
                    }
                }
            }

            .title {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }
}
