.wallet-card {
    .card-body {
        overflow-x: auto;
    }

    .wallet-card-buttons {
        display: flex;
        flex-flow: row nowrap;

        >*:not(:last-child) {
            margin-right: 10px;
        }

        button {
            white-space: nowrap;
        }
    }
}

.wallet-modal-modal {
    .modal-body {
        display: flex;
        justify-content: center;
        .form {
            display: flex;
            flex-flow: row nowrap;
            .form-control{
                border-color: #a8a5a5;
            }
            .mb-3{
                margin: 0 5px 0 0 !important;
            }

            .plus-minus-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border-radius: 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: #d22d3d;
                border: 1px solid #d22d3d;
                background-color: #f0ebeb;
                &.add {
                    flex-flow: row-reverse nowrap;
                    color: #24695c;
                    border: 1px solid #24695c;
                    background-color: #ebf0ee;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        > .buttons {

        }
    }

}
