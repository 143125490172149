.buy-card{
  .card-buy{
  width: 40vw;
  }

}
@media screen and (max-width: 770px) {
  .buy-card{
    .card-buy{
      width: 90vw;
    }
  }
}

//confirm card media query
.buy-card{
  .confirm-receipt-container{
    width: 30vw;
    &.receipt{
      height:8vh;
      h2{
        margin-top: 8px;
      }
    }
  }
}


@media screen and (max-width: 770px) {
  .buy-card{
    .confirm-receipt-container{
      width: 70vw;
    }
  }
}
