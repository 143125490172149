@import 'utils/variables';

:root {
    --theme-default: #26695c;
    --theme-secondary: #c29873;
}

@import 'theme/general';
@import 'theme/generic';
@import 'theme/buttons';
@import 'theme/card';
@import 'theme/list';
@import 'theme/box-layout';
@import 'theme/icons';
@import 'theme/loader';
@import 'theme/dropdown';
@import 'theme/form-input';
@import 'theme/forms';
@import 'theme/base-inputs';
@import 'theme/radio';
@import 'theme/login';
@import 'theme/custom-theme-options';
@import 'theme/header';
@import 'theme/navs';
@import 'theme/sidebar';
@import 'theme/switch';
@import 'theme/tab';
